<template>
  <div>
    <div class="lg:hidden">
      <div
        class="
          px-6
          py-2
          flex flex-col
          text-text-primary
          lg:mt-32 lg:min-h-screen
        "
      >
        <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto font-semibold">Promote & Earn</h2>
        </header>
        <BPageLoader
          v-if="
            isLoading ||
            promotedFundraiserResponse.isLoading ||
            walletResponse.isLoading
          "
          class="flex-grow"
        />
        <main v-else>
          <router-link
            :to="{
              name: 'PromoteAndEarnWallet',
              params: { userId: authUser.id },
            }"
            class="block rounded bg-bg-alternate-9 p-4 sm:py-6 my-2 text-center"
          >
            <h5 class="font-medium uppercase text-xs text-text-alternate-13">
              wallet balance
            </h5>
            <p class="font-bold text-lg pt-2">
              ₦ {{ walletResponse.data ? walletResponse.data?.balance : 0 }}
            </p>
          </router-link>
          <button
            @click="getUserBankDetails()"
            class="
              font-bold
              text-sm text-text-primary
              rounded
              border
              py-3.5
              w-full
              mb-2
              focus:outline-none
            "
          >
            <BButtonLoader v-if="isLoading" class="mx-auto h-4 w-4" />
            <span v-else>Withdraw</span>
          </button>
          <div class="py-3 text-center">
            <h3 class="text-lg font-semibold text-text-alternate-1 my-4">
              Earn upto 50% per promotion.
            </h3>
            <p class="text-xs mt-4 font-medium">
              Promote a fundraiser and get upto 50% of the total amount you help
              the fundraiser raise.
            </p>
          </div>

          <hr class="my-4 text-text-alternate-8" />

          <div v-if="promotedFundraiserResponse.data.length">
            <p class="text-xs font-semibold text-center uppercase">
              Promoted Fundraisers
            </p>
            <router-link
              v-for="(item, index) in promotedFundraiserResponse.data"
              :key="index"
              :to="{ name: 'PromotedFundraiser', params: { id: item.id } }"
              class="
                block
                rounded
                p-4
                sm:py-6
                text-text-primary
                my-4
                border border-border-primary
              "
            >
              <h4
                class="font-semibold text-sm flex items-center justify-between"
              >
                <span class="truncate">{{ item.fundraiser.title }}</span>
                <ChevronDownIcon
                  class="h-5 fill-current transform -rotate-90 ml-6"
                />
              </h4>
              <div class="text-xs flex justify-between mt-4 items-baseline">
                <p class="font-bold text-lg">
                  ₦ {{ item.raisedAmount.toLocaleString() }}
                </p>
                <p class="font-semibold text-2xs">
                  <span class="text-text-alternate-3 font-medium mr-1"
                    ><span class=" text-text-danger">{{ item.fundraiser.promoteAndEarnCommPercent }}%</span> Earned:
                  </span>
                  <span>  &#8358;{{ item.earnedAmount }} </span>
                </p>
              </div>
            </router-link>
          </div>

          <div class="text-center" v-else>
            <div class="flex pt-8 flex-col items-center justify-center">
              <span class="p-6 bg-bg-alternate-3 rounded-full">
                <ResultEmptyIcon class="fill-current h-10" />
              </span>
              <h4 class="font-medium capitalize text-md mt-8">
                No Promoted Fundraiser yet
              </h4>
            </div>
            <p class="text-xs mt-4 font-medium">
              Go to the fundraisers page to start promoting a fundraiser now!
            </p>

            <div class="mt-20">
              <router-link
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  py-3
                  px-10
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                :to="{ name: 'Feeds' }"
              >
                Take me there
              </router-link>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8 pb-12">
        <div class="px-26 py-2 flex flex-col text-text-primary">
          <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold">Promote & Earn</h2>
          </header>
          <BPageLoader
            v-if="isLoading || promotedFundraiserResponse.isLoading"
            class="flex-grow"
          />
          <main v-else>
            <router-link
              :to="{
                name: 'PromoteAndEarnWallet',
                params: { userId: authUser.id },
              }"
              class="
                block
                rounded
                bg-bg-alternate-9
                p-4
                sm:py-6
                my-2
                text-center
              "
            >
              <h5 class="font-medium uppercase text-xs text-text-alternate-13">
                wallet balance
              </h5>
              <p class="font-bold text-lg pt-2">
                ₦ {{ walletResponse.data ? walletResponse.data?.balance : 0 }}
              </p>
            </router-link>
            <button
              @click="getUserBankDetails()"
              class="
                font-bold
                text-sm text-text-primary
                rounded
                border
                py-3.5
                w-full
                mb-2
                focus:outline-none
              "
            >
              <BButtonLoader v-if="isLoading" class="mx-auto h-4 w-4" />
              <span v-else>Withdraw</span>
            </button>
            <div class="py-3 text-center">
              <h3 class="text-lg font-semibold text-text-alternate-1 my-4">
                Earn upto 50% per promotion.
              </h3>
              <p class="text-xs mt-4 font-medium">
                Promote a fundraiser and get upto 50% of the total amount you
                help the fundraiser raise.
              </p>
            </div>

            <hr class="my-4 text-text-alternate-8" />

            <div v-if="promotedFundraiserResponse.data.length">
              <p class="text-xs font-medium uppercase">Promoted Fundraisers</p>
              <router-link
                v-for="(item, index) in promotedFundraiserResponse.data"
                :key="index"
                :to="{ name: 'PromotedFundraiser', params: { id: item.id } }"
                class="
                  block
                  rounded
                  p-4
                  sm:py-6
                  text-text-primary
                  my-4
                  border border-border-primary
                "
              >
                <h4
                  class="
                    font-semibold
                    text-sm
                    flex
                    items-center
                    justify-between
                  "
                >
                  <span class="truncate">{{ item.fundraiser.title }}</span>
                  <ChevronDownIcon
                    class="h-5 fill-current transform -rotate-90 ml-6"
                  />
                </h4>
                <div class="text-xs flex justify-between mt-4 items-baseline">
                  <p class="font-bold text-lg">
                    ₦ {{ item.raisedAmount.toLocaleString() }}
                  </p>
                  <p class="font-semibold text-2xs">
                    <span class="text-text-alternate-3 font-medium mr-1"
                      > <span class=" text-text-danger">{{ item.fundraiser.promoteAndEarnCommPercent }}%</span> Earned: 
                    </span>
                    <span> &#8358;{{ item.earnedAmount }} </span>
                  </p>
                </div>
              </router-link>
            </div>

            <div class="text-center" v-else>
              <div class="flex pt-8 flex-col items-center justify-center">
                <span class="p-6 bg-bg-alternate-3 rounded-full">
                  <ResultEmptyIcon class="fill-current h-10" />
                </span>
                <h4 class="font-medium capitalize text-md mt-8">
                  No Promoted Fundraiser yet
                </h4>
              </div>
              <p class="text-xs mt-4 font-medium">
                Go to the fundraisers page to start promoting a fundraiser now!
              </p>

              <div class="mt-10">
                <router-link
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    py-3
                    px-10
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  :to="{ name: 'Feeds' }"
                >
                  Take me there
                </router-link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>

  <BModal
    :isOpen="isWithdraw"
    @close="isWithdraw = false"
    :heading="'Withdraw from wallet'"
  >
    <BPageLoader v-if="bankResponse.isLoading" />
    <div class="flex flex-col items-center pb-6" v-else-if="!bankResponse.data">
      <span
        class="
          h-24
          w-24
          rounded-full
          bg-bg-alternate-3
          flex
          items-center
          justify-center
          my-4
        "
      >
        <BankIcon class="h-8 fill-current" style="fill: #323232" />
      </span>
      <h4 class="text-text-primary font-semibold my-4">No bank account</h4>
      <p class="text-center font-medium text-xs text-text-primary px-3">
        You have not added any withdrawal bank account. Click the button below
        to add a receiving account.
      </p>
      <router-link
        class="
          font-bold
          mx-auto
          w-full
          mt-8
          py-4
          xs:py-5
          text-sm text-center text-btn-text-primary
          bg-btn-bg-primary
          inline-block
          rounded
        "
        :to="{ name: 'Payment' }"
      >
        Add Bank Account
      </router-link>
    </div>
    <div class="flex flex-col pb-24 lg:pt-6" v-else>
      <div class="flex flex-col mt-6">
        <label class="text-text-primary text-sm font-semibold">Amount(₦)</label>
        <div
          class="
            flex
            items-center
            border
            rounded
            my-2
            overflow-hidden
            input-shadow
          "
          :class="
            showError ? 'border border-border-danger' : 'border-border-primary'
          "
        >
          <input
            class="
              py-3.5
              pl-3.5
              text-sm
              font-medium
              flex-grow
              focus:outline-none
            "
            @input="validateAmount(walletResponse.data.balance)"
            required
            type="number"
            :disabled="withdrawResponse.isLoading"
            v-model.number="amount"
            id="amount"
          />
          <!-- <BCurrencyInput
            v-model.number="amount"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              autoDecimalDigits: true,
            }"
          /> -->
          <div class="cursor-pointer px-3.5" @click="handleMaxBtnClick">
            <p class="text-xs">MAX</p>
          </div>
        </div>
        <p v-if="showError" class="text-text-danger text-2xs">
          {{ showError }}
        </p>
      </div>

      <div class="mt-3">
        <p class="text-text-primary text-sm font-semibold">Receiving Account</p>
        <div
          class="border rounded border-border-primary p-4 text-xs flex mt-2.5"
        >
          <BankIcon class="h-6 fill-current" style="fill: #323232" />
          <div class="flex flex-col justify-between px-3 pb-1">
            <p class="font-semibold text-text-primary truncate uppercase">
              {{ bankResponse.data.accountname }}
            </p>
            <p class="text-text-alternate-3 font-semibold my-1">
              {{ bankResponse.data.accountnumber }}
            </p>
            <p class="text-text-alternate-3 font-medium uppercase">
              {{ bankResponse.data.bankname }}
            </p>
          </div>
        </div>
        <div
          class="
            px-6
            py-4
            flex
            gap-4
            z-10
            bg-bg-alternate-3
            absolute
            left-0
            w-full
            bottom-14
          "
        >
          <button
            class="
              bg-btn-bg-primary
              focus:outline-none
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!amount || showError || withdrawResponse.isLoading"
            @click="withdrawalFromWallet"
          >
            <BButtonLoader
              class="h-4 w-4 mx-auto"
              v-if="withdrawResponse.isLoading"
            />
            <span v-else>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { useToast } from 'vue-toastification';
// import BCurrencyInput from '../../../components/ui/BCurrencyInput.vue';
import {
  getPromotedFundraisers,
  getUserBank,
  promoteAndEarnWithdrawal,
  getPromoteAndEarnWallet,
} from '@/services/api';
import { useStore } from 'vuex';
import BModal from '../../../components/ui/BModal.vue';

const store = useStore();
const totalEarnings = ref(0);
const authUser = store.state.auth.authData;
const isWithdraw = ref(false);
const amount = ref('');
const toast = useToast();
const [promotedFundraiserResponse, fetchPromotedFundraisers] = useApi(
  getPromotedFundraisers
);
const [bankResponse, fetchUserBank] = useApi(getUserBank);
const [withdrawResponse, makeWithdrawals] = useApi(promoteAndEarnWithdrawal);

const [walletResponse, fetchPromoteAndEarnWallet] = useApi(
  getPromoteAndEarnWallet
);

fetchPromoteAndEarnWallet(authUser.id);

watch(promotedFundraiserResponse, ({ isSuccess, data }) => {
  if (isSuccess) {
    totalEarnings.value = data.reduce((acc, val) => acc + val.earnedAmount, 0);
  }
});

watch(withdrawResponse, ({ isSuccess, isFailure }) => {
  if (isSuccess) {
    toast.success('You withdrawal has been received and awaiting approval!');
    isWithdraw.value = false;
    fetchPromoteAndEarnWallet(authUser.id);
  } else if (isFailure) {
    // toast.error('An error occured');
  }
});

fetchPromotedFundraisers(authUser.id);

const getUserBankDetails = () => {
  isWithdraw.value = true;
  fetchUserBank(authUser.id);
};

const withdrawalFromWallet = () => {
  const payload = {
    bankId: bankResponse.data.id,
    userId: authUser.id,
    amount: amount.value,
  };
  makeWithdrawals(payload);
};

const handleMaxBtnClick = () => {
  amount.value = ''
  amount.value = walletResponse.data.balance;
  // validateAmount(walletResponse.data.balance);
};
</script>
